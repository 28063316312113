.formWrapper {
  --neutral-400: #8d9999;
  --neutral-900: #161038;
  --primary-100: #dfe7e7;
  --accent-200: #ff6501;
  --text-color-light: #fffcfa;
  --error-200: #ffddd3;
  --error-400: #dc2626;

  color: var(--neutral-950);
}

.disclaimerWrapper a {
  text-decoration: underline;
}
